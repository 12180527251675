import React from 'react';
import LayoutWrapper from 'components/LayoutWrapper';
import VetProfile from '../components/VetProfile';

const VetProfilePage = (): FC => {
  return (
    <LayoutWrapper>
      <VetProfile />
    </LayoutWrapper>
  );
};

export default VetProfilePage;
