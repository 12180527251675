import styled from 'styled-components';

export const VetProfileStyles = styled.div`
  // padding: 84px 0px;
  // .ant-spin {
  //   display: flex;
  //   justify-content: center;
  // }
  // .personal-content {
  //   background: #eaf7fc;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   flex-direction: column;
  //   padding: 50px 0px;

  //   .background-img {
  //     width: 220px;
  //     background: #fff;
  //     height: 220px;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     border-radius: 100%;
  //     margin-bottom: 15px;
  //   }

  //   .vet-img {
  //     width: 200px;
  //     height: 200px;
  //     border-radius: 100%;
  //     object-fit: cover;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     background: #29ABE2;

  //     .vet-icon {
  //       width: 100px;
  //       height: auto;
  //     }
  //   }

  //   .name {
  //     font-family: DM Sans;
  //     font-size: 26px;
  //     font-style: normal;
  //     font-weight: 700;
  //     line-height: 30px;
  //     text-align: center;
  //   }

  //   .title {
  //     font-family: DM Sans;
  //     font-size: 20px;
  //     font-style: normal;
  //     line-height: 28px;
  //     text-align: center;
  //     color: #29abe2;
  //   }
  // }

  // .vet-section {
  //   padding: 60px 90px;
  //   max-width: 1000px;
  //   margin: auto;

  //   .main-title {
  //     font-family: DM Sans;
  //     font-size: 24px;
  //     font-style: normal;
  //     font-weight: 700;
  //     line-height: 40px;
  //   }

  //   .sub-title {
  //     font-family: DM Sans;
  //     font-size: 20px;
  //     font-style: normal;
  //     font-weight: 700;
  //     line-height: 32px;
  //     letter-spacing: -0.25px;
  //   }

  //   .desc {
  //     font-family: DM Sans;
  //     font-size: 18px;
  //     font-style: normal;
  //     font-weight: 400;
  //     line-height: 28px;
  //     color: #36464C;
  //   }

  //   .vet-feature {
  //     font-family: DM Sans;
  //     font-size: 18px;
  //     font-style: normal;
  //     font-weight: 400;
  //     line-height: 28px;
  //     color: #29ABE2;
  //   }
  // }
`;
